import React from "react"

const PublicationItem = ({ title, authors, date }) => {
  return (
    <div className="col col-d-12 col-t-12 col-m-12 border-line-h">
      <div className="box-item">
        <div className="desc">
          <a className="name">{title}</a>
          <div className="category">
            Authors:
            <br />
            {authors}
            <br />
            {date}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicationItem
