import React from "react"

const ServicesList = ({ list }) => {

  const getServiceItem = (index, title, description, iconClass, isLastOnes) => {
    const itemClass = `col col-d-6 col-t-6 col-m-12${!isLastOnes && " border-line-h"}`
    return (
      <div key={index} className={itemClass}>
        <div className="service-item">
          <div className="icon">
            <span className={iconClass}></span>
          </div>
          <div className="name">{title}</div>
          <p>{description}</p>
        </div>
      </div>
    )
  }
  return (
    <div className="row service-items border-line-v">
      {list.map((item, index) => {
        const isLastOnes = index >= list.length - 2
        return getServiceItem(
          index,
          item.title,
          item.description,
          item.iconClass,
          isLastOnes
        )
      })}
    </div>
  )
}

export default ServicesList;

