import React, { useState } from "react"
import HeaderMenuItem from "./headerMenuItem"
import CARDS from "../../models/cards";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const HeaderMenu = ({handleScroll}) => {
  const [activeCard, setActiveCard] = useState(CARDS.ABOUT)
  const { height, width } = useWindowDimensions();

  const handleContactMeButton = (event) => {
    openCard(CARDS.CONTACT);
  };

  const openCard = card => {
    const fadeIn = "fadeInLeft"
    const fadeOut = "fadeOutLeft"
    setActiveCard(card)
    if (width < 1024){ // TODO : IF IS MOBILE
      handleScroll(card.id)
    } else {
        const cardElem = document.querySelector(`#${card.id}`)
        closeAllCards()
        cardElem.classList.remove("animated", fadeOut)
        cardElem.classList.add("animated", fadeIn)
    }
  }

  const closeAllCards = () => {
    Object.values(CARDS).forEach(card => closeCard(card))
  }

  const closeCard = card => {
    const fadeIn = "fadeInLeft"
    const fadeOut = "fadeOutLeft"
    const cardElem = document.querySelector(`#${card.id}`)
    cardElem.classList.remove("animated", fadeIn)
    cardElem.classList.add("animated", fadeOut)
  }

  return (
    <header className="header">
      <div className="top-menu">
        <ul>
          {Object.values(CARDS).map((c, index) => (
            <HeaderMenuItem
              card={c}
              activeCard={activeCard}
              onItemClick={openCard}
              key={index}
            />
          ))}
        </ul>
      </div>
    </header>
  )
}

export default HeaderMenu
