import React from "react"

const SkillItemList = ({ list }) => {
  const getSkillItem = (title, isLastItem, index) => {
    return (
      <li key={index} className={isLastItem ? "border-line-h" : ""}>
        <div className="name">{title}</div>
      </li>
    )
  }

  return (
    <ul>
      {list.map((item, index) => {
        const isLastItem = index === list.length - 1
        return getSkillItem(item.title, isLastItem, index)
      })}
    </ul>
  )
}

export default SkillItemList;