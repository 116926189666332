import React from "react"

const AboutContent = ({description, country, state}) => {
  return (
    <div className="row">
      <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
        <div className="text-box">
          <p>{description.text}</p>
          <em>{description.citation}</em>
        </div>
        <br/>
        <div className="info-list">
          <ul>
            <li>
              <strong>Residence . . . . .</strong>{" "}
              {country +
                ", " +
                state}
            </li>
          </ul>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default AboutContent;