import React from "react"
import { Link } from "gatsby"

const ProfileCover = ({ cover }) => {
  const backgroundImgStyle = {
    backgroundImage: "url(" + "bhsite/images/bg2.jpg" + ")",
  }
  return (
    <div className="profile">
      {/* < !--profile image-- > */}
      <div className="slide" style={backgroundImgStyle}></div>

      {/* <!--profile photo-- > */}
      <div className="image">
        <img src={cover.profileImg} alt=""></img>
      </div>

      {/* <!-- profile titles --> */}
      <div className="title">{cover.name}</div>
      <div className="subtitle">{cover.position}</div>

      {/* <!-- profile socials --> */}
      <div className="social">
        <a href={cover.links.linkedin}> {/* target="_blank" */}
          <span className="fab fa-linkedin"></span>
        </a>
        <a href={cover.links.github}> {/* target="_blank" */}
          <span className="fab fa-github"></span>
        </a>
        <a href={cover.links.stackoverflow}> {/* target="_blank" */}
          <span className="fab fa-stack-overflow"></span>
        </a>
      </div>

      <div className="lnks">
        <Link to="/blog/" className="lnk discover blink" style={{ width: "100%" }}>
          <span className="text">Go to Blog </span>
          <span className="fas fa-angle-right"></span>
        </Link>
      </div>
    </div>
  )
}

export default ProfileCover
