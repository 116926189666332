import React from 'react';
import WorkItem from './workItem';
import WORK_FILTERS from '../../models/workFilters';

const WorkList = ({ list, filter }) => {
  let projectCounter = 0;

  const getFilteredList = () => {
    switch(filter){
      case WORK_FILTERS.ALL:
          return Object.values(list).flat();
      case WORK_FILTERS.DATA_SCIENCE:
          return [...list.dataScience];
      case WORK_FILTERS.MOBILE_WEB:
          return [...list.mobileAndWeb];
      case WORK_FILTERS.OTHERS:
          return [...list.others];
    } 
  }

  return (
    <div className='row grid-items border-line-v'>
      {getFilteredList().map((item, index, array) => {
        const useBottomBorder = index !== array.length - 1 && index !== (array.length - 1) / 2;
        const workItem = (
          <WorkItem
            // category={categ}
            project={item}
            useBottomBorder={useBottomBorder}
            index={projectCounter}
            key={projectCounter}
          />
        );
        projectCounter++;
        return workItem;
      })}
    </div>
  );
};

export default WorkList;