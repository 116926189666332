import React from "react"

const HeaderMenuItem = ({ card, activeCard, onItemClick }) => {
  return (
    <li className={activeCard.id === card.id ? "active" : ""}>
      <a onClick={() => onItemClick(card)}>
        <i className={`icon fas ${card.iconClass}`}></i>
        <span className="link">{card.text}</span>
      </a>
    </li>
  )
}

export default HeaderMenuItem
