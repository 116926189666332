import React from "react"

const ResumeItem = ({ item, isActive, isLastItem }) => {

  const getItemClass = (isActive, isLastItem) => {
    var itemClass = "resume-item"
    itemClass += isLastItem ? "" : " border-line-h"
    itemClass += isActive ? " active" : ""
    return itemClass
  }

  return (
    <div
      className={getItemClass(isActive, isLastItem)}
    >
      <div className="date">{item.period}</div>
      <div className="name">{item.title}</div>
      <div className="company">{item.organization}</div>
      <p>{item.description}</p>
    </div>
  )
}

export default ResumeItem
