import React, { useState } from "react"
import WorkList from "./workList"
import WORK_FILTERS from "../../models/workFilters"
import MyData from "../../data"

const WorksCard = ({}) => {
  const [workFilter, setWorkFilter] = useState(WORK_FILTERS.ALL)

  const getFiterMenu = () => {
    return Object.values(WORK_FILTERS).map(w => (
      <div key={w} className={`f_btn ${w === workFilter ? "active" : ""}`}>
        <label>
          <input
            type="radio"
            name="fl_radio"
            onClick={() => {
              setWorkFilter(w)
            }}
          />
          {w}
        </label>
      </div>
    ))
  }

  return (
    <div className="card-wrap">
      {/* <!-- Works --> */}
      <div className="content works">
        {/* <!-- title --> */}
        <div className="title">
          Projects
          <br />
          worked on
        </div>

        {/* <!-- filters --> */}
        <div className="filter-menu filter-button-group">{getFiterMenu()}</div>

        {/* <!-- content --> */}
        <WorkList list={MyData.works} filter={workFilter} />
        {/* <div id="work-list-content" className="row grid-items border-line-v">
              <div className="col col-d-6 col-t-6 col-m-12 grid-item design border-line-h">
                <div className="box-item">
                  <div className="image">
                    <a href="#popup-1" className="has-popup-media">
                      <img src="" alt="" />
                      <span className="info">
                        <span className="ion ion-images"></span>
                      </span>
                    </a>
                  </div>
                  <div className="desc">
                    <a href="#popup-1" className="name has-popup-media">Mobile Application</a>
                    <div className="category">Design</div>
                  </div>
                  <div id="popup-1" className="popup-box mfp-fade mfp-hide">
                    <div className="content">
                      <div className="image">
                        <img src="" alt="" />
                      </div>
                      <div className="desc">
                        <div className="post-box">
                          <h1>Mobile Application</h1>
                          <div className="blog-detail">Design</div>
                          <div className="blog-content">
                            <p>
                              So striking at of to welcomed resolved. Northward by described up household therefore attention. Excellence decisively nay
                              man yet impression for contrasted remarkably.
                                                </p>
                            <p>
                              Forfeited you engrossed but gay sometimes explained. Another as studied it to evident. Merry sense given he be arise. Conduct
                              at an replied removal an amongst. Remaining determine few her two cordially admitting old.
                                                </p>
                            <blockquote>
                              Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Pellentesque suscipit.
                                                </blockquote>
                            <p>
                              Tiled say decay spoil now walls meant house. My mr interest thoughts screened of outweigh removing. Evening society musical
                              besides inhabit ye my. Lose hill well up will he over on. Increasing sufficient everything men him admiration
                              unpleasing sex.
                                                </p>
                            <ul className="list-style">
                              <li>Greatest properly off ham exercise all.</li>
                              <li>Unsatiable invitation its possession nor off.</li>
                              <li>All difficulty estimating unreserved increasing the solicitude.</li>
                            </ul>
                            <p>
                              Unpleasant astonished an diminution up partiality. Noisy an their of meant. Death means up civil do an offer wound of.
                                                </p>
                          </div>
                          <a href="#" className="button">
                            <span className="text">View Project</span>
                            <span className="arrow"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
      </div>
    </div>
  )
}

export default WorksCard
