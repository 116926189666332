import React from "react"
import MyData from "../../data"

const ContactInformations = () => {
  const email = MyData.contact.email;
  return (
    // div class="row" ??
    <div className="col col-d-12 col-t-12 col-m-12">
      <div className="contact_form">
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Message me at LinkedIn:
          <a href="https://www.linkedin.com/in/bruno-haetinger/">
            {/* target="_blank" */}
            <span
              style={{
                color: "steelblue",
                marginLeft: "0.2em",
                fontSize: "2rem",
              }}
              className="fab fa-linkedin"
            ></span>
          </a>
        </span>

        <span>
          <br />
          <br />
          or send an e-mail to:
        </span>
          <br />
        <a style={{padding: '2em'}}>{email}</a>
      </div>
    </div>
  )
}

export default ContactInformations
