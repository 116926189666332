import React from "react"

const FunFactList = ({ funFacts }) => {

  function getFunFactItem(title, iconClass, index) {
    return (
      <div key={index} className="col col-d-3 col-t-3 col-m-6 border-line-v">
        <div className="fuct-item">
          <div className="icon">
            <span className={iconClass}></span>
          </div>
          <div className="name">{title}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="row fuct-items">
      {funFacts.map((item, index) => getFunFactItem(item.title, item.iconClass, index))}
    </div>
  )
}

export default FunFactList;