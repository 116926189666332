import { graphql } from "gatsby"
import React, { useRef } from "react"
import { Helmet } from "react-helmet"

import "../styles/layout.scss"
import "../styles/gradient.scss"
import "../styles/basic.scss"
import "../styles/animate.scss"

import SEO from "../components/seo"
import MyData from "../data"
import AboutContent from "../components/home/aboutContent"
import ClientItem from "../components/home/clientItem"
import ContactInformations from "../components/home/contactInformations"
import FunFactList from "../components/home/funFactList"
import ProfileCover from "../components/home/profileCover"
import ResumeItemList from "../components/home/resumeList"
import ServicesList from "../components/home/servicesList"
import SkillItemList from "../components/home/skillList"
import HeaderMenu from "../components/home/headerMenu"
import WorksCard from "../components/home/worksCard"
import CARDS from "../models/cards"
import PublicationItem from "../components/home/publicationItem"

const IndexPage = () => {
  const aboutRef = useRef(null)
  const resumeRef = useRef(null)
  const worksRef = useRef(null)
  const papersRef = useRef(null)
  const contactRef = useRef(null)

  const scrollToRef = ref => {
    const offsetTop = ref.current.offsetTop
    document.querySelector("html").scrollTo(0, offsetTop)
  }
  const scrollToCard = cardID => {
    switch (cardID) {
      case CARDS.ABOUT.id:
        scrollToRef(aboutRef)
        break
      case CARDS.RESUME.id:
        scrollToRef(resumeRef)
        break
      case CARDS.WORKS.id:
        scrollToRef(worksRef)
        break
      case CARDS.BLOG.id:
        scrollToRef(papersRef)
        break
      case CARDS.CONTACT.id:
        scrollToRef(contactRef)
        break
    }
  }

  return (
    // <Layout location={this.props.location} title={siteTitle}>
    // </Layout>
    <>
      <SEO title="Bruno Haetinger" />
      <Helmet htmlAttributes={{ class: "html-scroll" }}>
        <meta charset="utf-8" />
        <title>Bruno Haetinger</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <link
          rel="stylesheet"
          href="https://www.w3schools.com/w3css/4/w3.css"
        />
        <link rel="stylesheet" type="text/css" href="./fontawesome/all.css" />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins"
          rel="stylesheet"
        />
      </Helmet>
      <header></header>
      <main>
        <div className="index-page">
          <div className="background gradient">
            <ul className="bg-bubbles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>

          <div className="container opened">
            <HeaderMenu handleScroll={scrollToCard} />
            <div className="card-started" id="home-card">
              <ProfileCover
                cover={MyData.cover}
                scrollToContactCard={() => scrollToRef(contactRef)}
              />
            </div>

            {/* <!-- Card - About --> */}
            <div
              ref={aboutRef}
              className="card-inner animated active"
              id="about-card"
            >
              <div className="card-wrap">
                {/* <!-- About --> */}
                <div className="content about">
                  {/* <!-- title --> */}
                  <div className="title">About Me</div>

                  <AboutContent
                    description={MyData.about.description}
                    country={MyData.about.residence.country}
                    state={MyData.about.residence.state}
                  />
                </div>

                {/* <!-- Services --> */}
                <div className="content services">
                  {/* <!-- title --> */}
                  <div className="title">My Specialities</div>

                  {/* <!-- content --> */}
                  <ServicesList list={MyData.about.services} />
                </div>

                {/* <!-- Fun Fact --> */}
                <div className="content fuct">
                  {/* <!-- title --> */}
                  <div className="title">Fun Fact</div>

                  {/* <!-- content --> */}
                  <FunFactList funFacts={MyData.about.funFacts} />
                </div>

                {/* <!-- Clients --> */}
                <div className="content clients">
                  {/* <!-- title --> */}
                  <div className="title">Clients</div>

                  <ClientItem client={MyData.about.clients[0]}></ClientItem>
                </div>
              </div>
            </div>

            {/* <!-- Card - Resume  --> */}
            <div ref={resumeRef} className="card-inner" id="resume-card">
              <div className="card-wrap">
                {/* <!-- Resume --> */}
                <div className="content resume">
                  {/* <!-- title --> */}
                  <div className="title">Resume</div>

                  {/* <!-- content --> */}
                  <div className="row">
                    {/* <!-- experience --> */}
                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                      <div className="resume-title border-line-h">
                        <div className="icon">
                          <i className="fas fa-briefcase"></i>
                        </div>
                        <div className="name">Experience</div>
                      </div>
                      <ResumeItemList
                        list={[...MyData.resume.experience].reverse()}
                      />
                    </div>

                    {/* <!-- education --> */}
                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                      <div className="resume-title border-line-h">
                        <div className="icon">
                          <i className="fas fa-graduation-cap"></i>
                        </div>
                        <div className="name"> Education</div>
                      </div>
                      <ResumeItemList
                        list={[...MyData.resume.education].reverse()}
                      />
                    </div>

                    <div className="clear"></div>
                  </div>
                </div>

                {/* <!-- Skills --> */}
                <div className="content skills">
                  {/* <!-- title --> */}
                  <div className="title">My Skills</div>

                  {/* <!-- content --> */}
                  <div className="row">
                    {/* <!-- skill item --> */}
                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                      <div className="skills-list">
                        <div className="skill-title border-line-h">
                          <div className="icon">
                            <i className="fas fa-code"></i>
                          </div>
                          <div className="name">Tech</div>
                        </div>
                        <SkillItemList
                          list={MyData.resume.mySkills[0].subItens}
                        />
                      </div>
                    </div>

                    {/* <!-- skill item --> */}
                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                      <div className="skills-list">
                        <div className="skill-title border-line-h">
                          <div className="icon">
                            <i className="fas fa-puzzle-piece"></i>
                          </div>
                          <div className="name">Add-ons</div>
                        </div>
                        <SkillItemList
                          list={MyData.resume.mySkills[1].subItens}
                        />
                      </div>
                    </div>

                    <div className="clear"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Card - Works --> */}
            <div ref={worksRef} className="card-inner" id="works-card">
              <WorksCard />
            </div>
            {/* <!-- Card - Blog --> */}
            <div ref={papersRef} className="card-inner blog" id="blog-card">
              <div className="card-wrap">
                {/* <!-- Blog --> */}
                <div className="content blog">
                  {/* <!-- title --> */}
                  <div className="title">Papers</div>

                  {/* <!-- content --> */}
                  <div className="row border-line-v">
                    {MyData.publications.map(publication => (
                      <PublicationItem
                        key={publication.title}
                        {...publication}
                      />
                    ))}

                    <div className="clear"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Card - Contacts --> */}
            <div
              ref={contactRef}
              className="card-inner contacts"
              id="contacts-card"
            >
              <div className="card-wrap">
                {/* <!-- Conacts Info --> */}
                <div className="content contacts">
                  {/* <!-- title --> */}
                  <div className="title">Get in Touch</div>
                </div>

                {/* <!-- Contact Form --> */}
                <div className="content contacts">
                  {/* <!-- content --> */}
                  <ContactInformations />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
  // }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
