import React from "react"
import ResumeItem from "./resumeItem"

const ResumeItemList = ({ list }) => {
  const itemList = list.map((item, index) => {
    const isLastItem = index === list.length - 1;
    const isActive = item.period.indexOf("present") !== -1;
    return (
      <ResumeItem
        key={item.organization}
        item={item}
        isActive={isActive}
        isLastItem={isLastItem}
      ></ResumeItem>
    )
  });
  return (
    <div className="resume-items">
      {itemList}
    </div>
  )
}

export default ResumeItemList;
