import React from 'react'
import Image from "gatsby-image"

const ClientItem = ({client}) => {
  return (
    //<!-- client item -->
    <div className="col col-d-3 col-t-3 col-m-6 border-line-v">
      <div className="client-item">
        <div className="image">
          <a href={client.url}> {/* target="_blank" */}
            <img 
              style={{
                width: '140px',
                height: 'auto',
                marginBottom: 0,
              }} 
              src={client.img} 
              alt="Client" 
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ClientItem;