const CARDS = {
    ABOUT: {
      id: "about-card",
      text: "About",
      iconClass: "fa-user",
    },
    RESUME: {
      id: "resume-card",
      text: "Resume",
      iconClass: "fa-file-alt",
    },
    WORKS: {
      id: "works-card",
      text: "Works",
      iconClass: "fa-code",
    },
    BLOG: {
      id: "blog-card",
      text: "Papers",
      iconClass: "fa-newspaper",
    },
    CONTACT: {
      id: "contacts-card",
      text: "Contact",
      iconClass: "fa-at",
    },
  }

export default CARDS;