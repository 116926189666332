import React from 'react'
import WorkItemPopup from './workItemPopup';

const WorkItem = ({useBottomBorder, project, index}) => {
    const getContainerClass = (useBottomBorder) => {
        var baseClass = "col col-d-6 col-t-6 col-m-12 grid-item";
        baseClass += useBottomBorder ? " border-line-h" : "";
        return baseClass;
    }
    const getPopup = () => {
        return <WorkItemPopup project={project} popupId={getPopupId()}></WorkItemPopup>
    }
    const getPopupId = () => {
        return "popup-work-item-" + index;
    }

    return (
            <div className={getContainerClass(useBottomBorder)}>
                <div className="box-item">
                    <div className="image">
                        <a href={"#" + getPopupId()} className="has-popup-media">
                            <img src={project.img} alt="" />
                            <span className="info">
                                <span className="fas fa-info-circle"></span>
                            </span>
                        </a>
                    </div>
                    <div className="desc">
                        <a href={"#" + getPopupId()} className="name has-popup-media">{project.title}</a>
                        <div className="category">{project.category}</div>
                    </div>
                    {/* {getPopup()} */}
                </div>
            </div>
    );
}
  
export default WorkItem;